<template>
    <q-dialog v-model="show">
        <q-card class="q-dialog-plugin">
            <div class="dialog-title">玩法规则</div>
            <div class="article">
                <p>
                    <font color="#8baa4a">0、1、2、3、4</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('小') }}</font>
                    <br />
                    <font color="#8baa4a">5、6、7、8、9</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('大') }}</font>
                    <br />
                    <font color="#8baa4a">1、3、5、7、9</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('单') }}</font>
                    <br />
                    <font color="#8baa4a">0、2、4、6、8</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('双') }}</font>
                </p>
            </div>
        </q-card>
    </q-dialog>

    <span @click="show = true">
        <slot name="btn"></slot>
    </span>
</template>
<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            show: false,
            text: ""
        }
    },
    emits: ['onClickBtn'],
    methods: {
        onClickBtn() {
            this.$emit('onClickBtn', this.text)
            this.show = false
        }
    }
}
</script>
<style scoped>
.q-dialog-plugin {
    max-height: calc(100vh - 108px);
    background: #000;
    color: #fff;
    border: 2px solid #f56ef9;
}

.dialog-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    padding: 16px;
}
.article {
  color: #fff;
  padding: 0 20px 20px 20px;
}
</style>